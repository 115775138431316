<template>
   <div class="dewha-container position-relative w-100">
        <div class="d-flex align-items-center justify-content-center dewha-logo-section">
        <div class="">
            <lazy-image class="dewha-logo w-100" src="https://images.quizell.com/gallery/8611727080242.png"></lazy-image>
        </div>
    </div>
    <div class="skin-detail-section">
       <div class="d-flex justify-content-center align-items-center flex-column">
        <div class="skin-type-section">
            <div class="dewha-black-text">YOUR SKIN TYPE IS</div>
            <div class="dewha-short-heading">{{`${firstLetter(results.Q1Q3)}${firstLetter(results.Q4Q7)}${firstLetter(results.Q8Q12)}${firstLetter(results.Q13Q20)}`}}</div>
            <div class="dewha-short-detail">{{`${removeParentheses(results.Q1Q3)}, ${removeParentheses(results.Q4Q7)}, ${removeParentheses(results.Q8Q12)} , ${removeParentheses(results.Q13Q20)}`}}</div>
        </div>
        <div class="model-image-dev position-relative">
            <div class="model-image-info eye-info">DARK UNDER EYE</div>
            <div class="model-image-info right-info">FLAKY AREA</div>
            <div class="model-image-info right-info1">DARK UNDER EYE</div>
            <lazy-image class=" w-100" src="https://images.quizell.com/gallery/1851727263154.png"></lazy-image>
        </div>
       </div>
    </div>
    <div class="position-relative skin-type-section ">
    <div class="skin-type-boxes flex-column d-flex justify-content-center justify-content-center ">
        <div class="skin-center-box d-flex justify-content-center flex-wrap">
        <div class="" v-if="firstLetter(results.Q1Q3)" >
            <div class="skin-type-box">
                <div class="">
                <div class="dewha-big-ch">{{firstLetter(results.Q1Q3)}}</div>
                <div class="dewha-skin-type-title">{{removeParentheses(results.Q1Q3) }}</div>
            </div>
                <div class="dewha-skin-type-detail">{{description[removeParentheses(results.Q1Q3)]}}
</div>
</div>
            </div>
            <div class="" v-if="firstLetter(results.Q4Q7)" >
            <div class="skin-type-box">
                <div class="">
                <div class="dewha-big-ch">{{firstLetter(results.Q4Q7)}}</div>
                <div class="dewha-skin-type-title">{{removeParentheses(results.Q4Q7) }}</div>
            </div>
                <div class="dewha-skin-type-detail">{{description[removeParentheses(results.Q4Q7)]}}
</div>
</div>
            </div>
            <div class="" v-if="firstLetter(results.Q8Q12)">
            <div class="skin-type-box">
                <div class="">
                <div class="dewha-big-ch">{{firstLetter(results.Q8Q12)}}</div>
                <div class="dewha-skin-type-title">{{removeParentheses(results.Q8Q12) }}</div>
            </div>
                <div class="dewha-skin-type-detail">{{description[removeParentheses(results.Q8Q12)]}}
</div>
</div>
            </div>
            <div class="" v-if="firstLetter(results.Q13Q20)">
            <div class="skin-type-box">
                <div class="">
                <div class="dewha-big-ch">{{firstLetter(results.Q13Q20)}}</div>
                <div class="dewha-skin-type-title">{{removeParentheses(results.Q13Q20) }}</div>
            </div>
                <div class="dewha-skin-type-detail">{{description[removeParentheses(results.Q13Q20)]}}
</div>
</div>
            </div>
        </div>
        <!-- Tips -->
        <div class="">
            <div class="dewha-skin-tips-section mt-5 pt-md-5 d-flex align-items-center flex-column">
                <div class="dewha-border d-md-none mb-5"></div>
        <div class="tips-most-heading">Skincare Tips for 
            {{`${firstLetter(results.Q1Q3)}${firstLetter(results.Q4Q7)}${firstLetter(results.Q8Q12)}${firstLetter(results.Q13Q20)}`}} Skin Type</div>
            <div class="dewha-tips-detail mt-4 mt-md-5">
            <div class="tips-sub-heading mb-2">Focus on intense hydration: </div>
            <div class="tips-detail">Use products with ingredients like hyaluronic acid, ceramides,
                and glycerin to deeply hydrate and nourish the skin.</div>
            </div>
            <div class="dewha-tips-detail mt-4 mt-md-5">
            <div class="tips-sub-heading mb-2">Prioritize sun protection: </div>
            <div class="tips-detail">Incorporate SPF 30+ products to shield your skin 
                from UV damage and prevent further pigmentation and aging.</div>
            </div>
            <div class="dewha-tips-detail mt-4 mt-md-5">
            <div class="tips-sub-heading mb-2">Consistent use of recommended products:  </div>
            <div class="tips-detail">Regular application of appropriate products will 
                help maintain a clearer, more radiant complexion.</div>
            </div>
    </div>
        </div>
        <!-- Products -->
         <div class="dewha-product-section mt-5">
            <div class="product-recommendation-heading mb-3">RECOMMENDED PRODUCTS</div>
           <div class="d-flex align-items-center justify-content-center flex-wrap dewha-products">
            <div class="dewha-product-card" v-for="(product,index) in selectedProducts" :key="index">
                <div class="dewha-card-body">
                    <div class="dewha-card-padding">
                    <div class="dewha-product-title">{{product.title}}</div>
                        <div class="d-flex justify-content-center my-3">
                        <div class="dewha-product-image">
                            <lazy-image class="w-100"  style="object-fit:cover; transform: translate(5%, -25%) scale(1.5);"  :src="product.image"></lazy-image>
                        </div>
                        
                    </div>
                    <div class="dewha-product-description mb-4" v-html="product.description">
                           

                        </div>
                    </div>
                        <div class="dewha-product-price w-100">₱ {{product.price}}</div>
                </div>
                <div class="d-flex justify-content-center mt-5">
                    <div class="">
                        <a :href="product.detail_link" class="dewha-product-button">Add to cart</a>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props:{
            allProducts:Array,
        },
    data(){
        
      return{
          pageDesign: {},
          selectedQuestionOptions: window.quizell.selectedQuestionOptions,
      results: {
        Q1Q3: null,
        Q4Q7: null,
        Q8Q12: null,
        Q13Q20: null
      },
      // Ranges as data properties
      q1_q3_ranges: [
        [1, 3.99, "Dry (very)"],
        [4, 6.99, "Dry (slight)"],
        [7, 9.99, "Oily (slight)"],
        [10, 12, "Oily (very)"]
      ],
      q4_q7_ranges: [
        [4, 6.99, "Resistant (very)"],
        [7, 9.99, "Resistant (slight)"],
        [10, 12.99, "Sensitive (slight)"],
        [13, 16, "Sensitive (very)"]
      ],
      q8_q12_ranges: [
        [5, 7.99, "Pigmented (very)"],
        [8, 12.99, "Pigmented (slight)"],
        [13, 16.99, "Non-Pigmented (slight)"],
        [17, 20, "Non-Pigmented (very)"]
      ],
      q13_q20_ranges: [
        [8, 11.99, "Tight (very)"],
        [12, 15.99, "Tight (slight)"],
        [16, 23.99, "Wrinkle-prone (slight)"],
        [24, 32, "Wrinkle-prone (very)"]
      ],
      description:{
        Oily:`Excess sebum production, leading to a shiny complexion.`,
        Dry:`Insufficient oil production, resulting in dry, flaky skin.`,
        Resistant:`Skin that rarely reacts adversely to products, allowing for experimentation.`,
        Sensitive:`Skin that is prone to irritation and requires gentle formulations.`,
        Pigmented:'Skin with uneven tone and areas of discoloration.',
        "Non-Pigmented":`Skin with an even tone and minimal discoloration.`,
        "Tight":"Skin that feels firm and plump, often reflecting good hydration.",
        "Wrinkle-Prone":"Skin that shows a tendency to develop fine lines and wrinkles"

      },
      selectedProducts:[],
      } 
    },
    computed:{
        ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getSelectedOptions",
      "getPreviewSettings",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getEnvConst",
      "getTotalPreviewPages",
      "getSliderValues",
      "getkozimaIntervalValue"
    ]),
    getProductArrayIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "product"
      );

      if (index > -1) {
        return index;
      } else {
        return null;
      }
    },
        getProductBlock() {
      return this.pageDesign.blocksArray[this.getProductArrayIndex];
    },
    getResultPageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
},
mounted(){
    this.pageDesign = this.getResultPageValues;
    this.productCount();
       // Calculate the score ranges based on question groups
       const q1_q3_score = this.calculateScore(0, 3);
    const q4_q7_score = this.calculateScore(3, 7);
    const q8_q12_score = this.calculateScore(7, 12);
    const q13_q20_score = this.calculateScore(12);

    // Getting skin type results using ranges from data properties
    this.results.Q1Q3 = this.determineSkinType(q1_q3_score, this.q1_q3_ranges);
    this.results.Q4Q7 = this.determineSkinType(q4_q7_score, this.q4_q7_ranges);
    this.results.Q8Q12 = this.determineSkinType(q8_q12_score, this.q8_q12_ranges);
    this.results.Q13Q20 = this.determineSkinType(q13_q20_score, this.q13_q20_ranges);
},
methods:{
    productCount(){
        const {
    productsPerPage,
    rows,
    productsPerPageMobile,
    mobileRows
  } = this.getProductBlock;

  const totalProducts = this.isDesktopView
    ? productsPerPage * rows
    : productsPerPageMobile * (mobileRows || rows);
    this.selectedProducts = this.allProducts.slice(0, totalProducts);
    
    },
    calculateScore(start, end) {
      const slice = end ? this.selectedQuestionOptions.slice(start, end) : this.selectedQuestionOptions.slice(start);
      return slice.reduce((acc, entry) => acc + entry.selectedOption.score_value, 0);
    },
    determineSkinType(score, ranges) {
      for (let range of ranges) {
        if (range[0] <= score && score <= range[1]) {
          return range[2];
        }
      }
      return null;
    },
    removeParentheses(text) {
      return text ? text.replace(/\s*\(.*?\)\s*/g, '') : '';
    },
    firstLetter(str){
        console.log(str)
      return  str?str[0]:''
    }
}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.dewha-logo-section{
    box-shadow: 0px 4px 6.8px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 100px;
    margin: 0px 20px 0px 20px;
}
.dewha-container{
    background-image: url(https://images.quizell.com/gallery/2221727182465.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
    height: 1268px;
}
.dewha-container1{
    padding: 0px 20px 0px 20px;
    --dewha-primary:#F14257;
    --dewha-secondary:#393939;
}
.dewha-logo{
    width: 200px;
height: 82px;
margin-top: 17px;
margin-bottom: 17px;
}
.model-image-dev{
    max-width: 1087px;
}
.skin-detail-section{
    margin-top:59px;
}
.dewha-black-text{
    color: #1E1E1E;
text-align: center;
font-family: "Work Sans";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dewha-short-heading{
    color: #47A8DE;
text-align: center;
font-family: "Work Sans";
font-size: 66px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.96px;
}
.dewha-short-detail{
    color: #47A8DE;
text-align: center;
font-family: "Work Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.25px;
margin-bottom:15px;
}
.model-image-info{
    border-radius: 29px;
border: 1px solid #000;
background: rgba(255, 255, 255, 0.76);
color: #393939;
text-align: center;
font-family: "Atkinson Hyperlegible";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.26px;
padding:15px;
max-width: 276px;
}
.eye-info{
    position: absolute;
    top: 78px;
    left: 1%;
}
.right-info {
    position: absolute;
    top: 117px;
    right: 38px;
    }
    .right-info1 {
        position: absolute;
    top: 247px;
    left: 1%;
    }
    .skin-type-boxes{
    position: absolute;
    width: 100%;
    top: -348px;
    background: url(https://images.quizell.com/gallery/1371727271360.png);
    background-blend-mode: screen;
    
    }
    .skin-type-box{
        border-radius: 60px;
background: linear-gradient(180deg, #9CDBFF 0%, #47A8DE 100%);
box-shadow: 0px 4px 6.8px 0px rgba(0, 0, 0, 0.10);
padding: 22px;
width: 175px;
min-height: 320px;
display: flex;
flex-direction: column;
justify-content: space-between;
    }
    .dewha-big-ch{
        color: #FFF;
text-align: center;
font-family: "Atkinson Hyperlegible";
font-size:100px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -3.45px;
line-height: 0.9;
    }
    .dewha-skin-type-title{
        color: #FFF;
text-align: center;
font-family: Amiko;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;

    }
    .dewha-skin-type-detail{
        color: #FFF;
text-align: center;
font-family: Amiko;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .skin-center-box{
        gap:15px;
        margin-top: 265px;
       
    }
    .tips-most-heading{
        color: #2E97D1;
text-align: center;
font-family: "Work Sans";
font-size: 38px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.56px;
    }
    .dewha-border{
        content: '';
        display: block;
        width: 80%;
        border:2px dashed #47A8DE;
    }
    .tips-sub-heading{
        color: #47A8DE;
text-align: center;
font-family: "Work Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.3px;
    }
   .tips-detail {
        color: #47A8DE;
text-align: center;
font-family: "Work Sans";
font-size: 20px;
font-style: normal;

line-height: normal;
letter-spacing: 0.3px;
    }
    .dewha-tips-detail{

    }
    .dewha-product-section{
        border-radius: 100px 100px 0px 0px;
        background: url(https://images.quizell.com/gallery/7551727342767.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .product-recommendation-heading{
        color: #1E1E1E;
text-align: center;
font-family: "Work Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
    .dewha-card-body{
        border-radius: 56px;
background: #FFF;
box-shadow: 0px 20px 32px 4px rgba(0, 0, 0, 0.15);
min-height: 509px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
overflow: hidden;
    }
    .dewha-card-padding{
        padding: 83px 57px 0px 57px;
    }
    .dewha-product-title{
        color: #1E1E1E;
text-align: center;
font-family: "Work Sans";
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: 27px; /* 108% */
letter-spacing: 0.5px;
    }
   .dewha-product-card {
        max-width: 365px;
    }
    .dewha-product-image{
        width: 190px;
    height: 190px;
    object-fit: cover;
    overflow: hidden;
    }
    .dewha-product-description{
        color: #1E1E1E;
text-align: center;
font-family: "Work Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
letter-spacing: 0.64px;
width: 250px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    }
    .dewha-product-price{
border-radius: 0px 0px 56px 56px;
background: #F1D7D8;
box-shadow: 0px 20px 32px 4px rgba(0, 0, 0, 0.15);
color: #FFF;
text-align: center;
font-family: "Work Sans";
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 27px; /* 112.5% */
letter-spacing: 0.48px;
padding: 30px;
    }
    .dewha-product-button{
        border-radius: 87px;
background: #F1D7D8;
padding: 17px 41px;
text-decoration: none;
color: #393939;
text-align: center;
font-family: "Work Sans";
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 27px; /* 135% */
letter-spacing: 0.4px;
margin: 30px auto;
    }
    .dewha-products{
        gap:77px;
    }
@media (min-width: 768px) {

    .dewha-logo{
    width: 138px;
height: 60px;
margin-top: 17px;
margin-bottom: 17px;
}
.dewha-logo-section{
    border-radius: 0px;
    width: 100%;
    margin: 0px;
}
.model-image-dev{
    width: 828px;
    max-width: 828px;
}
.skin-detail-section{
    margin-top:55px;
}
.dewha-black-text{
    color: #1E1E1E;
text-align: center;
font-family: "Work Sans";
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.dewha-short-heading{
    color: #47A8DE;
text-align: center;
font-family: "Work Sans";
font-size: 96px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.96px;
line-height: 0.8;
}
.dewha-short-detail{
    color: #47A8DE;
text-align: center;
font-family: "Work Sans";
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.25px;

}
.model-image-info{
    border-radius: 29px;
border: 1px solid #000;
background: rgba(255, 255, 255, 0.76);
color: #393939;
text-align: center;
font-family: "Atkinson Hyperlegible";
font-size: 26px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.26px;
padding:15px;

}
.eye-info{
    position: absolute;
    top: 200px;
    left: 18px;
}
.right-info {
        position: absolute;
        top: 289px;
        right: 113px;
    }
    .right-info1{
        position: absolute;
    top: 456px;
    left: 6%;
    }
  
    .skin-type-box{
        border-radius: 31px;
        width: 210px;
        padding:30px;
    }
    .skin-center-box{
        gap:40px;
        margin-top: 255px;
       
       
    }
    .skin-type-boxes{
        top: -400px;
    }
    .dewha-big-ch{
        font-size: 115px;
    }
    /* .dewha-skin-type-title{
        margin-bottom: 35px;
    } */
    .tips-most-heading{
        font-size: 56px;
    }
.dewha-tips-detail{
    width: 75%;
    }
    .tips-sub-heading{
        font-size: 30px;
    }
    .tips-detail {
        font-size: 28px;
    }
    .dewha-product-section{
        padding: 75px 98px;
    }
    .product-recommendation-heading{
        font-size: 50px;
    }
}
@media (min-width: 1024px) {

 
    .dewha-container1{
        padding: 0px 88px 0px 88px;
    }
  
    .dewha-card-image{
        width: 480px;
    }
}
</style>